import axios, {AxiosResponse} from 'axios';
import getConfig from '../getConfig';

const {api} = getConfig();

/**
 * A Middleware is a function that takes an AxiosResponse and returns its relevant data only
 */
export type Middleware = (response: AxiosResponse) => unknown;

/**
 * Since the response is wrapped in a nested object, this middleware returns the relevant data only
 * @param response
 */
const getRoot: Middleware = (response): unknown => response.data.data;

/**
 * A thunk accepting a GraphQL query and returning a new function performing the given query.
 * Example:
 *
 * const getUser = performQuery(`query {...}`);
 *
 * getUser().then(...);
 *
 * @param query
 * @param middleware
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const performQuery = (query: string, middleware: Middleware = getRoot) => (): Promise<any> =>
 {
   return axios({
     url: api.matchcenter.root,
     method: 'GET',
     params: {
       query: query.replace(/\s+/g, ' ').trim(),
       apikey: api.matchcenter.key,
     },
     headers: {
       'Content-Type': 'application/json',
     },
   }).then(middleware);
 }


export default performQuery;
