import {Dispatch} from 'redux';
import {
  COMPETITIONS_GET_INVALIDATE,
  COMPETITIONS_GET_REQUEST,
  COMPETITIONS_GET_RESPONSE,
} from 'store/actionTypes';
import standardizeError from '../../../utils/standardizeError';
import {
  CompetitionsAction,
  CompetitionsGetInvalidate,
  CompetitionsGetRequest,
  CompetitionsGetResponse,
} from '../types';
import {BECompetitionDetail, BESport} from '../../../API/types';
import {getCompetitions as APIgetCompetitions} from '../../../API/competitions';

/**
 * Action creator for the get request of the competitions array
 */
const competitionsGetRequest = (): CompetitionsGetRequest => ({
  type: COMPETITIONS_GET_REQUEST,
});

/**
 * Action creator for the get response of the competitions array
 */
const competitionsGetResponse = (data: BECompetitionDetail[]): CompetitionsGetResponse => ({
  type: COMPETITIONS_GET_RESPONSE,
  payload: {data},
});

/**
 * Action creator for the get invalidation of the competitions array
 * @param error
 */
const competitionsGetInvalidate = (error: Error): CompetitionsGetInvalidate => ({
  type: COMPETITIONS_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * Fetches the competitions from the related API and passes the data to the store
 */
const getCompetitions = () => (dispatch: Dispatch): Promise<CompetitionsAction> => {
  dispatch(competitionsGetRequest());

  return (
    APIgetCompetitions()
      // TODO: remove sport filters
      .then((competitions: BECompetitionDetail[]) =>
        competitions.filter(competition =>
          [BESport.SOCCER, BESport.BASKETBALL, BESport.TENNIS].includes(competition.sportType)
        )
      )
      .then((competitions: BECompetitionDetail[]) =>
        dispatch(competitionsGetResponse(competitions))
      )
      .catch(error => dispatch(competitionsGetInvalidate(error)))
  );
};

export default getCompetitions;
