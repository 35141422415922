import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../store/types';
import getCompetitionsThunk from '../store/competitions/actions/getCompetitions';
import {BECompetitionDetail} from '../API/types';

type UseCompetitionsResult = [
  {loading: boolean, data?: BECompetitionDetail[], error?: string | boolean},
  {getCompetitions: typeof getCompetitionsThunk},
];


/**
 * Selects the 'competitions' state and sum it up into a referring object
 */
const competitionsSelector = (state: AppState) => ({
  loading: state.competitions.isFetching,
  data: state.competitions.data,
  error: state.competitions.didInvalidate ? state.competitions.error : false,
});

/**
 * Wraps up the business logic of selecting the 'competitions' state.
 */
const useCompetitions = (): UseCompetitionsResult => {
  const dispatch = useDispatch();
  const state = useSelector(competitionsSelector);

  const getCompetitions = useCallback(() => dispatch(getCompetitionsThunk()), [dispatch]);

  return [state, {getCompetitions}];
};

export default useCompetitions;
