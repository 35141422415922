import React, {FC} from 'react';
import './styles.scss';

import noDataIcon from 'assets/svg/matchcenter_no_data.svg';

interface Props {
  message?: string;
}

const NoData: FC<Props> = ({message = 'Δεν υπάρχουν δεδομένα'}: Props) => {
  return (
    <div className="NoData">
      <img className="NoData__icon" src={noDataIcon} alt={message} />
      <p className="NoData__text">{message}</p>
    </div>
  );
};

export default NoData;
