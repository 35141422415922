export interface AppConfiguration {
  api: {
    matchcenter: {
      readonly root: string;
      readonly key: string;
      readonly hpGroupId: string;
    };
    tvprogram: {
      readonly root: string;
    };
    bet: {
      readonly root: string;
    };
  };
  pollingRate: number;
}

/**
 * Returns the application configuration, including env variables.
 */
const getConfig = (): AppConfiguration => ({
  api: {
    matchcenter: {
      root: <string>process.env.REACT_APP_MATCHCENTER_API_ROOT,
      key: <string>process.env.REACT_APP_MATCHCENTER_API_KEY,
      hpGroupId: <string>process.env.REACT_APP_MATCHCENTER_API_HP_GROUP_ID,
    },
    tvprogram: {
      root: <string>process.env.REACT_APP_TVPROGRAM_API_ROOT,
    },
    bet: {
      root: <string>process.env.REACT_APP_BET_API_ROOT,
    },
  },
  pollingRate: parseInt(<string>process.env.REACT_APP_POLLING_RATE, 10) || 60000,
});

export default getConfig;
