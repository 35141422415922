interface ITimerStore {
  add: (timerId: number) => void
  remove: (timerId: number) => void
  eject: () => number[],
}


/**
 * A store for the timer instances used by the Polling
 * @constructor
 */
const TimerStore = (): ITimerStore => {
  const instances: number[] = [];

  return Object.freeze({
    add: (timerId: number): void => {
      instances.push(timerId);
    },
    remove: (timerId: number) => {
      const i = instances.indexOf(timerId);
      instances.splice(i, 1);
    },
    eject: () => (instances),
  });
};

export default TimerStore;
