import MatchList from 'components/MatchList';
import useCompetitions from 'hooks/useCompetitions';
import React, {FC, Ref, RefObject, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';


import {groupMatchesByCompetition} from 'utils/matchFns';

import {MatchSimple, Sport, TVProgramEvent} from 'utils/types';

import './styles.scss';

const MatchcenterTopMatchesWidget: FC<{
  matches: MatchSimple[];
  root: HTMLElement | null;
}> = ({
  matches,
  root,
}: {
  matches: MatchSimple[];
  root: HTMLElement | null;
}) => {
  const [competitionsState] = useCompetitions();
  const {data: competitions} = competitionsState;

  const matchGroups = useMemo(
    () => groupMatchesByCompetition(matches, competitions),
    [matches, competitions]
  );

  return (
    root &&
    createPortal(
      <section className="MatchcenterTopMatchesWidget">
        <h1 className="MatchcenterTopMatchesWidget__header">
          <a href="/matchcenter/">MATCHCENTER</a>
        </h1>
        <MatchList matchGroups={matchGroups} />
        <a href="/matchcenter/" className="MatchcenterTopMatchesWidget__button">
          ΟΛΟΙ ΟΙ ΑΓΩΝΕΣ
        </a>
      </section>,
      root
    )
  );
};

export default React.memo(MatchcenterTopMatchesWidget);
