import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getScoreboardPanelsThunk from 'store/scoreboardPanels/actions/getScoreboardPanels';
import {BetDataAll, MatchSimple} from '../utils/types';
import {AppState} from '../store/types';

/**
 * Return type of the useMatches hook
 */
type UseScoreboardPanelsResult = [
  {loading: boolean; data: {[i: string]: {match:MatchSimple, bet?:BetDataAll}}; error?: string | boolean},
  {getScoreboardPanels: (widgets: {widgetId: string; matchId: string | undefined;}[]) => void}
];

/**
 * Selects the 'matches' state and sum it up into a referring object
 */
const scoreboardPanelsSelector = (state: AppState) => ({
  loading: state.scoreboardPanels.isFetching,
  data: state.scoreboardPanels.data || {},
  error: state.scoreboardPanels.didInvalidate ? state.scoreboardPanels.error : false,
});

/**
 * Wraps up the business logic of selecting the 'matches' state and its related actions.
 */
const useScoreboardPanels = (): UseScoreboardPanelsResult => {
  const dispatch = useDispatch();
  const state = useSelector(scoreboardPanelsSelector);

  const getScoreboardPanels = useCallback(
    (widgets: {widgetId: string; matchId?: string; bet?:BetDataAll}[]) =>
      dispatch(getScoreboardPanelsThunk(widgets)),
    [dispatch]
  );

  return [state, {getScoreboardPanels}];
};

export default useScoreboardPanels;
