import TimerStore from './TimerStore';

export interface PollingCancellableFunction extends Function {
  cancel: () => void;
  equals: (fn: AnyFunction) => boolean;
  isCancelled: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunction = (...args: any[]) => any;

const timers = TimerStore();

/**
 * The Polling object exposes the methods to perform a given API call every x milliseconds.
 * Usage:
 * ```
 * const repeatSomething = Polling.on(doSomething, 250);
 *
 * repeatSomething(); // repeat doSomething every 250ms.
 * repeatSomething.cancel();
 * ```
 */
const Polling = Object.freeze({
  /**
   * Receives a function and an interval of time (in milliseconds) and returns a new function
   * that performs the first one every interval-defined milliseconds.
   * The returned function is cancellable, and can be cancelled (aka stops the interval) by calling the fn.cancel() method.
   * @param fn
   * @param interval
   * @param immediateExec
   */
  on: (fn: AnyFunction, interval = 250, immediateExec = true): PollingCancellableFunction => {
    let timeout: number;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pollingFn: PollingCancellableFunction = (...args: any[]) => {
      timeout = window.setInterval(() => fn(...args), interval);
      timers.add(timeout);
      if (immediateExec) {
        fn(...args);
      }
    };

    pollingFn.isCancelled = false;

    pollingFn.cancel = () => {
      window.clearInterval(timeout);
      timers.remove(timeout);
      pollingFn.isCancelled = true;
    };

    pollingFn.equals = (someFn: AnyFunction) => someFn === fn;

    return pollingFn;
  },
  /**
   * Clears all the polling functions
   */
  clearAll: () => {
    timers.eject().forEach(clearInterval);
  },
});

export default Polling;
