import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getHomepageMatchesThunk from 'store/homepageWidgets/actions/getHomepageMatches';
import getTVProgramEventsThunk from 'store/homepageWidgets/actions/getTVProgramEvents';
import {HomepageWidget, MatchSimple, ScoresWidget, TvProgramWidget} from '../utils/types';
import {AppState} from '../store/types';

/**
 * Return type of the useMatches hook
 */
type UseTvProgramWidgetsResult = [
  {loading: boolean; data: {[i: string]: TvProgramWidget}; error?: string | boolean},
  {getTvProgramWidgets: (widgets: {widgetId: string; groupId?: string}[]) => void}
];

/**
 * Selects the 'matches' state and sum it up into a referring object
 */
const tvProgramWidgetsSelector = (state: AppState) => ({
  loading: state.tvProgramWidgets.isFetching,
  data: state.tvProgramWidgets.data || {},
  error: state.tvProgramWidgets.didInvalidate ? state.tvProgramWidgets.error : false,
});

/**
 * Wraps up the business logic of selecting the 'matches' state and its related actions.
 */
const useTvProgramWidgets = (): UseTvProgramWidgetsResult => {
  const dispatch = useDispatch();
  const state = useSelector(tvProgramWidgetsSelector);

  const getTvProgramWidgets = useCallback(
    (widgets: {widgetId: string;}[]) => {
      widgets.forEach(({widgetId}) => {
        const date = new Date();
        dispatch(getTVProgramEventsThunk(widgetId, date));
    })
    },
    [dispatch]
  );

  return [state, {getTvProgramWidgets}];
};

export default useTvProgramWidgets;
