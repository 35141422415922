import { TVProgramWidgetsAction, TVProgramWidgetsState} from './types';
import {TVPROGRAM_EVENTS_GET_INVALIDATE, TVPROGRAM_EVENTS_GET_REQUEST, TVPROGRAM_EVENTS_GET_RESPONSE} from '../actionTypes';

const initialState: TVProgramWidgetsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'tvProgramEvents' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const teaserArticlesReducer = (
  state = initialState,
  action: TVProgramWidgetsAction,
): TVProgramWidgetsState => {
  switch (action.type) {
    case TVPROGRAM_EVENTS_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case TVPROGRAM_EVENTS_GET_RESPONSE:
      return {
        ...state,
        isFetching: false,
        data: {
          ...(state.data || {}), 
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            tvProgramEvents: action.payload.data
          } },
      };
    case TVPROGRAM_EVENTS_GET_INVALIDATE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export default teaserArticlesReducer;
