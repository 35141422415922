import WidgetTvProgram from 'components/WidgetTvProgram';
import React, {FC, Ref, RefObject, useEffect, useMemo} from 'react';
import {createPortal} from 'react-dom';

import {MatchSimple, Sport, TVProgramEvent} from 'utils/types';

import './styles.scss';

const MatchcenterTvProgramWidget: FC<{
  tvData: TVProgramEvent[];
  root: HTMLElement | null;
}> = ({tvData, root}: {tvData: TVProgramEvent[]; root: HTMLElement | null}) => {
  return (
    root &&
    createPortal(
      <section className="MatchcenterTvProgramWidget">
        <h1 className="MatchcenterTvProgramWidget__header">
          <a href="/tvprogram/">ΠΡΟΓΡΑΜΜΑ TV</a>
        </h1>
        <WidgetTvProgram tvData={tvData} showHeader={false} />
        {/* eslint-disable react/jsx-no-target-blank */}
        <a
          className="MatchcenterTvProgramWidget__button"
          href="https://www.sport24.gr/tvprogram/"
          target="_blank"
        >
          ΑΝΑΛΥΤΙΚΟ ΠΡΟΓΡΑΜΜΑ TV
        </a>
      </section>,
      root
    )
  );
};

export default React.memo(MatchcenterTvProgramWidget);
