import React from 'react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import MatchcenterWidgetsRoot from './MatchcenterWidgetsRoot';
import store from './store';

const Index = () => (
  <Provider store={store}>
    <MatchcenterWidgetsRoot />
  </Provider>
);

ReactDOM.render(<Index />, document.getElementById('matchcenter-widgets-root'));
