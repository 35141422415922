import {Dispatch} from 'redux';
import {getBetDataById} from 'API/betData';
import {sportEventsBetDataByGroupId, sportEventsByGroupId, sportEventsByIds} from 'API/sportEvents';
import {
  BETDATA_BY_GROUP_ID_GET_INVALIDATE,
  BETDATA_BY_GROUP_ID_GET_REQUEST,
  BETDATA_BY_GROUP_ID_GET_RESPONSE,
} from '../../actionTypes';
import {
  HomepageWidgetBetDataGetInvalidateAction,
  HomepageWidgetBetDataGetRequestAction,
  HomepageWidgetBetDataGetResponseAction,
} from '../types';
import {
  BetData,
  BetProviderData,
  CompetitionTablesTeam,
  MatchBetData,
  MatchSimple,
  ScoreboardPanel,
} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {
  beBetDataToBetData,
  beBetDataToBetProviderData,
  beMatchBetDataToMatchBetData,
  beMatchSimpleToMatchSimple,
  beStandingToCompetitionTableTeams,
} from '../../../API/utils';
import {standingBySeasonIdOrGroupName} from '../../../API/standings';

/**
 * Action creator for the get request of the competitionTables by season id and groupname
 * @param id
 */
const getHomepageBetDataGetRequest = (): HomepageWidgetBetDataGetRequestAction => ({
  type: BETDATA_BY_GROUP_ID_GET_REQUEST,
});

/**
 * Action creator for the get response of the competitionTables by season id and groupname
 */
const getHomepageBetDataGetResponse = (
  widgetId: string,
  betData: BetData,
  betProvider: BetProviderData
): HomepageWidgetBetDataGetResponseAction => ({
  type: BETDATA_BY_GROUP_ID_GET_RESPONSE,
  payload: {widgetId, data: {betData, betProvider}},
});

/**
 * Action creator for the get invalidation of the competitionTables by season id and groupname
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getHomepageBetDataGetInvalidate = (
  error: Error
): HomepageWidgetBetDataGetInvalidateAction => ({
  type: BETDATA_BY_GROUP_ID_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * Thunk for getting the team competitionTables in the current season and group
 * @param seasonId
 * @param groupName
 */
const getBetData =
  (widgetId: string, groupId: string) =>
  (dispatch: Dispatch): Promise<any> => {
    dispatch(getHomepageBetDataGetRequest());

    return sportEventsBetDataByGroupId(groupId)
      .then(results => results.map(beMatchBetDataToMatchBetData))
      .then((matches: MatchBetData[]) => {
        return Promise.all(
          matches.map(match => {
            if (!match.betDataId) return null;

            return getBetDataById(match.betDataId)
              .then(
                results =>
                  [beBetDataToBetData(match, results), beBetDataToBetProviderData(results)] as [
                    BetData,
                    BetProviderData
                  ]
              )
              .then(([betData, betProvider]) =>
                dispatch(getHomepageBetDataGetResponse(widgetId, betData, betProvider))
              );
          })
        );
      })
      .catch((error: Error) => dispatch(getHomepageBetDataGetInvalidate(error)));
  };

export default getBetData;
