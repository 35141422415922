import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getScoresWidgetThunk from 'store/scoresWidgets/actions/getScoresWidget';
import {MatchSimple, ScoresWidget} from '../utils/types';
import {AppState} from '../store/types';

/**
 * Return type of the useMatches hook
 */
type UseScoresWidgetsResult = [
  {loading: boolean; data: {[i: string]: ScoresWidget}; error?: string | boolean},
  {getScoresWidgets: (widgets: {widgetId: string; matchIds?: string[]}[]) => void}
];

/**
 * Selects the 'matches' state and sum it up into a referring object
 */
const scoresWidgetsSelector = (state: AppState) => ({
  loading: state.scoresWidgets.isFetching,
  data: state.scoresWidgets.data || {},
  error: state.scoresWidgets.didInvalidate ? state.scoresWidgets.error : false,
});

/**
 * Wraps up the business logic of selecting the 'matches' state and its related actions.
 */
const useScoresWidgets = (): UseScoresWidgetsResult => {
  const dispatch = useDispatch();
  const state = useSelector(scoresWidgetsSelector);

  const getScoresWidgets = useCallback(
    (widgets: {widgetId: string; header?: string; matchIds?: string[]}[]) => {
      widgets.forEach(({widgetId, header = 'Scoreboard', matchIds}) => matchIds && !!matchIds.length && dispatch(getScoresWidgetThunk(widgetId, header, matchIds)));
    },
    [dispatch]
  );

  return [state, {getScoresWidgets}];
};

export default useScoresWidgets;
