// Sport events by group id
export const SPORTEVENTS_BY_GROUP_ID_GET_REQUEST =
  'matchcenter24/SPORTEVENTS_BY_GROUP_ID/GET_REQUEST';
export const SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE =
  'matchcenter24/SPORTEVENTS_BY_GROUP_ID/GET_RESPONSE';
export const SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE =
  'matchcenter24/SPORTEVENTS_BY_GROUP_ID/GET_INVALIDATE';

// BetData by group id
export const BETDATA_BY_GROUP_ID_GET_REQUEST = 'matchcenter24/BETDATA_BY_GROUP_ID/GET_REQUEST';
export const BETDATA_BY_GROUP_ID_GET_RESPONSE = 'matchcenter24/BETDATA_BY_GROUP_ID/GET_RESPONSE';
export const BETDATA_BY_GROUP_ID_GET_INVALIDATE =
  'matchcenter24/BETDATA_BY_GROUP_ID/GET_INVALIDATE';

// BetData by id
export const BETDATA_BY_ID_GET_REQUEST = 'matchcenter24/BETDATA_BY_ID/GET_REQUEST';
export const BETDATA_BY_ID_GET_RESPONSE = 'matchcenter24/BETDATA_BY_ID/GET_RESPONSE';
export const BETDATA_BY_ID_GET_INVALIDATE = 'matchcenter24/BETDATA_BY_ID/GET_INVALIDATE';

export const BETDATA_CLEANUP = 'matchcenter24/BETDATA_CLEANUP';

// Match Live Data by ids (SB)
export const SB_LIVE_DATA_BY_IDS_GET_REQUEST = 'matchcenter24/SB_LIVE_DATA_BY_IDS/GET_REQUEST';
export const SB_LIVE_DATA_BY_IDS_GET_RESPONSE = 'matchcenter24/SB_LIVE_DATA_BY_IDS/GET_RESPONSE';
export const SB_LIVE_DATA_BY_IDS_GET_INVALIDATE =
  'matchcenter24/SB_LIVE_DATA_BY_IDS/GET_INVALIDATE';

// Match Live Data by ids
export const MATCH_LIVE_DATA_BY_IDS_GET_REQUEST =
  'matchcenter24/MATCH_LIVE_DATA_BY_IDS/GET_REQUEST';
export const MATCH_LIVE_DATA_BY_IDS_GET_RESPONSE =
  'matchcenter24/MATCH_LIVE_DATA_BY_IDS/GET_RESPONSE';
export const MATCH_LIVE_DATA_BY_IDS_GET_INVALIDATE =
  'matchcenter24/MATCH_LIVE_DATA_BY_IDS/GET_INVALIDATE';

// Competitions fetching related actions
export const COMPETITIONS_GET_REQUEST = 'matchcenter24/COMPETITIONS/GET_REQUEST';
export const COMPETITIONS_GET_RESPONSE = 'matchcenter24/COMPETITIONS/GET_RESPONSE';
export const COMPETITIONS_GET_INVALIDATE = 'matchcenter24/COMPETITIONS/GET_INVALIDATE';

// Standings by season and group
export const STANDINGS_BY_SEASON_GROUP_GET_REQUEST =
  'matchcenter24/STANDINGS_BY_SEASON_GROUP/GET_REQUEST';
export const STANDINGS_BY_SEASON_GROUP_GET_RESPONSE =
  'matchcenter24/STANDINGS_BY_SEASON_GROUP/GET_RESPONSE';
export const STANDINGS_BY_SEASON_GROUP_GET_INVALIDATE =
  'matchcenter24/STANDINGS_BY_SEASON_GROUP/GET_INVALIDATE';

// TV program Events
export const TVPROGRAM_EVENTS_GET_REQUEST = 'tvprogram/TVPROGRAM_EVENTS/GET_REQUEST';
export const TVPROGRAM_EVENTS_GET_RESPONSE = 'tvprogram/TVPROGRAM_EVENTS/GET_RESPONSE';
export const TVPROGRAM_EVENTS_GET_INVALIDATE = 'tvprogram/TVPROGRAM_EVENTS/GET_INVALIDATE';
