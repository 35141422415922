import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getHomepageMatchesThunk from 'store/homepageWidgets/actions/getHomepageMatches';
import getTVProgramEventsThunk from 'store/homepageWidgets/actions/getTVProgramEvents';
import getBetDataThunk from 'store/homepageWidgets/actions/getBetData';
import {HomepageWidget, MatchSimple, ScoresWidget} from '../utils/types';
import {AppState} from '../store/types';

/**
 * Return type of the useMatches hook
 */
type UseHomepageWidgetsResult = [
  {loading: boolean; data: {[i: string]: HomepageWidget}; error?: string | boolean},
  {getHomepageWidgets: (widgets: {widgetId: string; groupId?: string}[]) => void}
];

/**
 * Selects the 'matches' state and sum it up into a referring object
 */
const homepageWidgetsSelector = (state: AppState) => ({
  loading: state.homepageWidgets.isFetching,
  data: state.homepageWidgets.data || {},
  error: state.homepageWidgets.didInvalidate ? state.homepageWidgets.error : false,
});

/**
 * Wraps up the business logic of selecting the 'matches' state and its related actions.
 */
const useHomepageWidgets = (): UseHomepageWidgetsResult => {
  const dispatch = useDispatch();
  const state = useSelector(homepageWidgetsSelector);

  const getHomepageWidgets = useCallback(
    (widgets: {widgetId: string; groupId?: string; betGroupId?: string; isMobile?: boolean}[]) => {
      widgets.forEach(({widgetId, groupId, betGroupId, isMobile}) => {
        if (groupId) {
          const date = new Date();
          dispatch(getHomepageMatchesThunk(widgetId, groupId, isMobile));
          dispatch(getTVProgramEventsThunk(widgetId, date));
          if (betGroupId) dispatch(getBetDataThunk(widgetId, betGroupId));
        }
      });
    },
    [dispatch]
  );

  return [state, {getHomepageWidgets}];
};

export default useHomepageWidgets;
