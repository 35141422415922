import axios from 'axios';
import {format} from 'date-fns';
import getConfig from '../getConfig';
import {Middleware} from './performQuery';
import {BETVProgramEvent} from './types';

const {api} = getConfig();

/**
 * Since the response is wrapped in a nested object, this middleware returns the relevant data only
 * @param response
 */
const getRoot: Middleware = (response): unknown => response.data;

/**
 * A thunk accepting a Date argument and returning a query for this date's TV program schedule.
 *
 * @param date
 * @param middleware
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const query = (date: Date, middleware: Middleware = getRoot) => (): Promise<any> =>
  axios({
    url: api.tvprogram.root,
    method: 'GET',
    params: {
      pId: 3,
      accept: 'json',
      date: format(date, 'yyyy-MM-dd'),
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(middleware);

export const getTVProgramEvents = (date: Date): Promise<BETVProgramEvent[]> => query(date)();

export default {getTVProgramEvents};
