import {CompetitionsAction, CompetitionsState} from './types';
import {COMPETITIONS_GET_INVALIDATE, COMPETITIONS_GET_REQUEST, COMPETITIONS_GET_RESPONSE} from '../actionTypes';

const initialState: CompetitionsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'competitions' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const competitionsReducer = (state = initialState, action: CompetitionsAction): CompetitionsState => {
  switch (action.type) {
    case COMPETITIONS_GET_REQUEST:
      return ({
        ...state,
        isFetching: true,
        didInvalidate: false,
      });
    case COMPETITIONS_GET_RESPONSE:
      return ({
        ...state,
        isFetching: false,
        data: action.payload.data,
      });
    case COMPETITIONS_GET_INVALIDATE:
      return ({
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      });
    default:
      return state;
  }
};

export default competitionsReducer;
