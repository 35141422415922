import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getTopMatchesThunk from 'store/topMatchesWidgets/actions/getTopMatchesWidget';
import {HomepageWidget, MatchSimple, ScoresWidget, TopMatchesWidget} from '../utils/types';
import {AppState} from '../store/types';

/**
 * Return type of the useMatches hook
 */
type UseTopMatchesWidgetsResult = [
  {loading: boolean; data: {[i: string]: TopMatchesWidget}; error?: string | boolean},
  {getTopMatchesWidgets: (widgets: {widgetId: string; groupId?: string}[]) => void}
];

/**
 * Selects the 'matches' state and sum it up into a referring object
 */
const topMatchesWidgetsSelector = (state: AppState) => ({
  loading: state.topMatchesWidgets.isFetching,
  data: state.topMatchesWidgets.data || {},
  error: state.topMatchesWidgets.didInvalidate ? state.topMatchesWidgets.error : false,
});

/**
 * Wraps up the business logic of selecting the 'matches' state and its related actions.
 */
const useTopMatchesWidgets = (): UseTopMatchesWidgetsResult => {
  const dispatch = useDispatch();
  const state = useSelector(topMatchesWidgetsSelector);

  const getTopMatchesWidgets = useCallback(
    (widgets: {widgetId: string; groupId?: string}[]) => {
      widgets.forEach(({widgetId, groupId}) => groupId && dispatch(getTopMatchesThunk(widgetId, groupId)))
    },
    [dispatch]
  );

  return [state, {getTopMatchesWidgets}];
};

export default useTopMatchesWidgets;
