/* eslint-disable react/jsx-no-target-blank */
import React, {FC, useEffect, useState} from 'react';

import {CompetitionTablesTeam} from 'utils/types';

import './styles.scss';

const TableRow = ({
  team: {teamName, wins, losses, ties, all, points, trend, status, sportSeason, pointsdiff, liveSportEvent},
  rank,
  narrow
  
}: {
  team: CompetitionTablesTeam;
  rank: number;
  narrow?: boolean;
}) => {
  const [hover, setHover] = useState(false);
  const [isWin, setIsWin] = useState(false)
  const [isLoss, setIsLoss] = useState(false)
  const [isTie, setIsTie] = useState(false)

  useEffect( () => {
    if (liveSportEvent) {
      switch(teamName) {
        case liveSportEvent.competitors[0].teamName : {
          if (liveSportEvent.homeScore > liveSportEvent.awayScore) {
            setIsTie(false)
            setIsLoss(false);
            setIsWin(true);
          }else if (liveSportEvent.homeScore < liveSportEvent.awayScore) {
            setIsWin(false);
            setIsTie(false)
            setIsLoss(true);
          } else {
            setIsWin(false);
            setIsTie(true)
            setIsLoss(false);
          }
          break;
        }
        case liveSportEvent.competitors[1].teamName : {
          if (liveSportEvent.homeScore > liveSportEvent.awayScore) {
            setIsTie(false)
            setIsLoss(true);
            setIsWin(false);
          }else if (liveSportEvent.homeScore < liveSportEvent.awayScore) {
            setIsWin(true);
            setIsTie(false)
            setIsLoss(false);
          } else {
            setIsWin(false);
            setIsTie(true)
            setIsLoss(false);
          }
          break;
        }
        default: 
        
      }
    }
  }, [liveSportEvent, teamName])

  return (
  <div className="WidgetTables__row" onMouseEnter={()=>setHover(true)} onFocus={()=>setHover(true)} onMouseLeave={()=>setHover(false)} onBlur={()=>setHover(false)}>
    <span className="WidgetTables__rank">{rank}</span>
    <div
      className={`WidgetTables__trend WidgetTables__trend--${trend} WidgetTables__trend--${liveSportEvent ? 'live' : status}`}
    />
    <span className={`WidgetTables__name WidgetTables__name${liveSportEvent ? "--live" : ""}${hover ? "--hover" : ""}`} >{teamName}</span>
    {(liveSportEvent) && ( 
        <a href={`/matchcenter/${liveSportEvent.sportEventSlug}/${liveSportEvent._id}`} className={`WidgetTables__liveMatch WidgetTables__liveMatch${hover ? "--visible" : ""}`}>
          LIVE<br/>
          {liveSportEvent.homeScore} - {liveSportEvent.awayScore} {liveSportEvent.competitors[0].teamName} - {liveSportEvent.competitors[1].teamName}
        </a>
    )}
    {!narrow && <span className={`WidgetTables__data WidgetTables__data--all WidgetTables__data--${liveSportEvent ? "live" : ""}`}>{all}</span>}
    <span className={`WidgetTables__data WidgetTables__data--wins WidgetTables__data--${isWin ? "live" : ""}`}>{wins}</span>
      {sportSeason === "soccer" ? <span className={`WidgetTables__data WidgetTables__data--ties WidgetTables__data--${isTie ? "live" : ""}`}>{ties}</span> : ""}
    <span className={`WidgetTables__data WidgetTables__data--losses WidgetTables__data--${isLoss ? "live" : ""}`}>{losses}</span>
      {sportSeason === "soccer" ? <span className={`WidgetTables__data WidgetTables__data--points WidgetTables__data--${liveSportEvent ? "live" : ""}`}>{points}</span> : ""}
      {sportSeason === "basketball" ? <span className={`WidgetTables__data WidgetTables__data--pointsdifference WidgetTables__data--${liveSportEvent ? "live" : ""}`}>{pointsdiff > 0 ? "+" : ""}{pointsdiff}</span> : ""}
  </div>
);
} 

interface Props {
  label?: string;
  competitionTables: CompetitionTablesTeam[];
  narrow?: boolean;
}

const WidgetTables: FC<Props> = ({label, competitionTables, narrow = false}: Props) => (
  <section className="WidgetTables">
    {label && <h3 className="WidgetTables__header">{label}</h3> }
    <div className="WidgetTables__tableHeader">
      {!narrow && <span className="WidgetTables__data WidgetTables__data--all">Α</span>}
      <span className="WidgetTables__data WidgetTables__data--wins">Ν</span>
        {competitionTables[0].sportSeason === "soccer" ? <span className="WidgetTables__data WidgetTables__data--ties">Ι</span> : "" }
      <span className="WidgetTables__data WidgetTables__data--losses">Η</span>
        {competitionTables[0].sportSeason === "soccer" ? <span className="WidgetTables__data WidgetTables__data--points">ΒΑΘ</span> : ""}
        {competitionTables[0].sportSeason === "basketball" ? <span className="WidgetTables__data WidgetTables__data--pointsdifference">ΔΠ</span> : ""}
    </div>
    {competitionTables.map((row, i) => (
      <TableRow team={row} rank={i + 1} key={row.teamName} narrow={narrow} />
    ))}

  </section>
);

export default WidgetTables;
