import {HomepageMatchesAction, HomepageWidgetsState} from './types';
import {
  SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE,
  SPORTEVENTS_BY_GROUP_ID_GET_REQUEST,
  SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE,
  BETDATA_BY_GROUP_ID_GET_INVALIDATE,
  BETDATA_BY_GROUP_ID_GET_REQUEST,
  BETDATA_BY_GROUP_ID_GET_RESPONSE,
  TVPROGRAM_EVENTS_GET_INVALIDATE,
  TVPROGRAM_EVENTS_GET_REQUEST,
  TVPROGRAM_EVENTS_GET_RESPONSE,
} from '../actionTypes';

const initialState: HomepageWidgetsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'standingsWidgets' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const HomepageMatchesReducer = (
  state: HomepageWidgetsState = initialState,
  action: HomepageMatchesAction
): HomepageWidgetsState => {
  switch (action.type) {
    case SPORTEVENTS_BY_GROUP_ID_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE: {
      const existingWidget = state.data?.[action.payload.widgetId];

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            matches: action.payload.data.matches,
            tvProgramEvents: existingWidget?.tvProgramEvents || [],
            betData: existingWidget?.betData,
            betProvider: existingWidget?.betProvider,
            isMobile: action.payload.data.isMobile,
          },
        },
      };
    }
    case SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case BETDATA_BY_GROUP_ID_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case BETDATA_BY_GROUP_ID_GET_RESPONSE: {
      const existingWidget = state.data?.[action.payload.widgetId];

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            matches: existingWidget?.matches || [],
            tvProgramEvents: existingWidget?.tvProgramEvents || [],
            betData: {
              ...(existingWidget?.betData || {}),
              [action.payload.data.betData.matchId]: action.payload.data.betData,
            },
            betProvider: existingWidget?.betProvider || action.payload.data.betProvider,
            isMobile: existingWidget?.isMobile,
          },
        },
      };
    }
    case BETDATA_BY_GROUP_ID_GET_INVALIDATE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    case TVPROGRAM_EVENTS_GET_REQUEST:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false,
      };
    case TVPROGRAM_EVENTS_GET_RESPONSE: {
      const existingWidget = state.data?.[action.payload.widgetId];

      return {
        ...state,
        isFetching: false,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            matches: existingWidget?.matches || [],
            tvProgramEvents: action.payload.data,
            betData: existingWidget?.betData,
            betProvider: existingWidget?.betProvider,
            isMobile: existingWidget?.isMobile,
          },
        },
      };
    }
    case TVPROGRAM_EVENTS_GET_INVALIDATE:
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export default HomepageMatchesReducer;
