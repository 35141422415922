import {useCallback, useRef} from 'react';
import Polling, {AnyFunction, PollingCancellableFunction} from '../utils/Polling/Polling';

export type MemoizedPollingCancellableFunction = (fn: AnyFunction, rate: number, immediateExec?: boolean) => PollingCancellableFunction;
export type PollingMemoizedMethods = {
  withPolling: MemoizedPollingCancellableFunction,
  clearAllPollings: () => void,
}


/**
 * Allows to use the Polling utility within React components so that every render doesn't create a new polling function.
 * It's used to create polling function at "component" level.
 *
 * Usage:
 *
 * ```
 * const withPolling = usePolling();
 * const memoizedPolling = withPolling(fetchSomething, 250);
 * ```
 */
const usePolling = (): PollingMemoizedMethods => {
  const fnsStoreRef = useRef<PollingCancellableFunction[]>([]);

  /**
   * Clear all the pending pollings
   */
  const clearAllPollings = useCallback(Polling.clearAll, []);

  /**
   * Creates a polling-version of the given function
   */
  const withPolling = useCallback((fn: AnyFunction, pollingRate: number, immediateExec = true): PollingCancellableFunction => {
    const pollingFn = fnsStoreRef.current.find((item) => item.equals(fn));

    if (!pollingFn) {
      const nextPollingFn = Polling.on(fn, pollingRate, immediateExec);
      fnsStoreRef.current.push(nextPollingFn);
      return nextPollingFn;
    }

    return pollingFn;
  }, []);

  return ({withPolling, clearAllPollings});
};

export default usePolling;

