import { TopMatchesWidgetsAction, TopMatchesWidgetsState} from './types';
import {
  SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE,
  SPORTEVENTS_BY_GROUP_ID_GET_REQUEST,
  SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE,
} from '../actionTypes';

const initialState: TopMatchesWidgetsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'standingsWidgets' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const TopMatchesWidgetsReducer = (state: TopMatchesWidgetsState = initialState, action: TopMatchesWidgetsAction): TopMatchesWidgetsState => {
  switch (action.type) {
    case SPORTEVENTS_BY_GROUP_ID_GET_REQUEST:
      return ({
        ...state,
        isFetching: true,
        error: undefined,
      });
    case SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE: {
      return ({
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: {
          ...(state.data || {}), 
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            matches: action.payload.data
          } },
      });
    }
    case SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE:
      return ({
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      });
    default:
      return state;
  }
};

export default TopMatchesWidgetsReducer;
