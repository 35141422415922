import axios from 'axios';
import {format} from 'date-fns';
import {BetData} from 'utils/types';
import getConfig from '../getConfig';
import {Middleware} from './performQuery';
import {BEBetData, BETVProgramEvent} from './types';

const {api} = getConfig();

/**
 * Since the response is wrapped in a nested object, this middleware returns the relevant data only
 * @param response
 */
const getRoot: Middleware = (response): unknown => response.data;

/**
 * A thunk accepting a Date argument and returning a query for this date's TV program schedule.
 *
 * @param date
 * @param middleware
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const query = (id: string, middleware: Middleware = getRoot) => (): Promise<any> =>
  axios({
    url: `${api.bet.root}/${id}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(middleware);

export const getBetDataById = (id: string): Promise<BEBetData> => query(id)();

export default {getBetDataById};
