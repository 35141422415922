import performQuery, {Middleware} from './performQuery';
import {createMiddleware} from './utils';
import {BECompetitionDetail} from './types';

/**
 * Fetches all the available competitions
 */
export const getCompetitions = (): Promise<BECompetitionDetail[]> => {
  const getRoot: Middleware = createMiddleware('competitions');

  const query = `{
    competitions {
      _id
      name
      competitionSlug
      competitionOrder
      sportType
      sportRadarId
    }
  }`;

  return performQuery(query, getRoot)();
};

export default {
  getCompetitions,
};
