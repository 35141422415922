import React, {FC} from 'react';

import './styles.scss';

/**
 * Displays a spinning icon
 * @constructor
 */
const Loader: FC<{fullpage?: boolean}> = ({fullpage}: {fullpage?: boolean}) => (
  <div className={`Loader ${fullpage ? 'Loader--fullpage' : ''}`}>
    <div className="Loader__icon">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default React.memo(Loader);
