import {applyMiddleware, compose, createStore} from 'redux';
import middleWares from './middlewares';
import combinedReducers from './combinedReducers';

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose;

const enhancers = composeEnhancers(applyMiddleware(...middleWares));

const initialState = {};

export default createStore(combinedReducers, initialState, enhancers);
