const undefinedErrorMessage = 'Unknown error';

type ErrorLike = {message: string};

/**
 * Returns the error message string from a given error or error-like object
 * @param error
 * @returns {string|*}
 */
const standardizeError = (error: Error | ErrorLike | string): string => {
  if (!error) return undefinedErrorMessage;
  if (error instanceof Error) return error.message;
  if (typeof error === 'string') return error;
  if (error.message && typeof error.message === 'string') return error.message;
  console.log(error);

  return undefinedErrorMessage;
};

export default standardizeError;
