import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import getStandingsWidgetThunk from 'store/standingsWidgets/actions/getStandingsWidget';
import {MatchSimple, ScoresWidget, StandingWidget} from '../utils/types';
import {AppState} from '../store/types';

/**
 * Return type of the useMatches hook
 */
type UseStandingsWidgetsResult = [
  {loading: boolean; data: {[i: string]: StandingWidget}; error?: string | boolean},
  {
    getStandingsWidgets: (
      widgets: {widgetId: string; seasonId?: string; groupId?: string; start?: string; end?: string}[]
    ) => void;
  }
];

/**
 * Selects the 'matches' state and sum it up into a referring object
 */
const standingsWidgetsSelector = (state: AppState) => ({
  loading: state.standingsWidgets.isFetching,
  data: state.standingsWidgets.data || {},
  error: state.standingsWidgets.didInvalidate ? state.standingsWidgets.error : false,
});

/**
 * Wraps up the business logic of selecting the 'matches' state and its related actions.
 */
const useStandingsWidgets = (): UseStandingsWidgetsResult => {
  const dispatch = useDispatch();
  const state = useSelector(standingsWidgetsSelector);

  const getStandingsWidgets = useCallback(
    (
      widgets: {widgetId: string; isCarousel?: boolean; header?: string; seasonId?: string; groupId?: string; start?: string; end?: string}[]
    ) => {
      widgets.forEach(({widgetId, isCarousel = false, header = 'Βαθμολογια', seasonId, groupId, start, end}) => {

        let startNum: number | undefined = typeof start === 'string' ? parseInt(start, 10) : undefined;
        if (Number.isNaN(startNum)) startNum = undefined;

        let endNum: number | undefined = typeof end === 'string' ? parseInt(end, 10) : undefined;
        if (Number.isNaN(endNum)) endNum = undefined;

        if (seasonId)
          dispatch(getStandingsWidgetThunk(widgetId, isCarousel, header, seasonId, groupId, startNum, endNum));
      });
    },
    [dispatch]
  );

  return [state, {getStandingsWidgets}];
};

export default useStandingsWidgets;
