import {Dispatch} from 'redux';
import {BETVProgramEvent} from '../../../API/types';
import {getTVProgramEvents as APIgetTVProgramEvents} from '../../../API/tvProgram';
import {TVProgramEvent} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {beTVProgramEventToTVProgramEvent} from '../../../API/utils';
import {TVPROGRAM_EVENTS_GET_INVALIDATE, TVPROGRAM_EVENTS_GET_REQUEST, TVPROGRAM_EVENTS_GET_RESPONSE} from '../../actionTypes';
import {HomepageWidgetTVProgramEventsGetInvalidateAction, HomepageWidgetTVProgramEventsGetRequestAction, HomepageWidgetTVProgramEventsGetResponseAction} from '../types';

/**
 * Action creator for the get request of the sport events by date
 * @param date
 */
const getTVProgramEventsGetRequest = (): HomepageWidgetTVProgramEventsGetRequestAction => ({
  type: TVPROGRAM_EVENTS_GET_REQUEST,
});

/**
 * Action creator for the request response of the sport events by date
 * @param matches
 * @param date
 */
const getTVProgramEventsGetResponse = (
  widgetId: string,
  data: TVProgramEvent[],
): HomepageWidgetTVProgramEventsGetResponseAction => ({
  type: TVPROGRAM_EVENTS_GET_RESPONSE,
  payload: {widgetId, data},
});

/**
 * Action creator for the request invalidate of the sport events by date
 * @param error
 */
const getTVProgramEventsGetInvalidate = (error: Error): HomepageWidgetTVProgramEventsGetInvalidateAction => ({
  type: TVPROGRAM_EVENTS_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * // TODO: document
 */
const getTVProgramEvents = (widgetId: string, date: Date) => (dispatch: Dispatch): Promise<any> => {
  dispatch(getTVProgramEventsGetRequest());

  return APIgetTVProgramEvents(date)
    .then((data: BETVProgramEvent[]) => {
      const events = data.map(beTVProgramEventToTVProgramEvent);
      // the `null` events are ignored sports or ignored times, and here we filter them away from the array
      return <TVProgramEvent[]>events
        .filter(e => e !== null)
        .slice(0, 10)
        .sort((e1, e2) =>
          (<NonNullable<TVProgramEvent>>e1).time < (<NonNullable<TVProgramEvent>>e2).time ? -1 : 1
        );
    })
    .then((events: TVProgramEvent[]) => dispatch(getTVProgramEventsGetResponse(widgetId, events)))
    .catch((error: Error) => dispatch(getTVProgramEventsGetInvalidate(error)));
};

export default getTVProgramEvents;
