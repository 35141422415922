import React, {FC, Fragment, useState} from 'react';

import {BetData, GenericProps} from 'utils/types';

import './styles.scss';

interface Props extends GenericProps {
  className?: string;
  betData: BetData;
}

const WidgetBet: FC<Props> = ({className, betData: {matchNames, matchUrl, odds}}: Props) => {
  return odds && !!odds.length ? (
    <section className={`WidgetBetWidget ${className ?? ''}`}>
      <h3 className="WidgetBetWidget__header">
        <a href={matchUrl} target="_blank" rel="external nofollow noopener noreferrer">
          <span>{matchNames[0]}</span> - <span>{matchNames[1]}</span>
        </a>
      </h3>
      <div className="WidgetBetWidget__oddsWrapper">
        {odds.map(group => (
          <Fragment key={group.id}>
            <h6 className="WidgetBetWidget__oddsHeader">{group.title}</h6>
            {group.values.map((value, i) => {
              let tileType;
              if (['MRES', 'BTSC', 'HCTG'].includes(group.type)) {
                if (i === 0) tileType = 'home';
                else if (i === group.values.length - 1) tileType = 'away';
                else tileType = 'tie';
              }
              return (
                <a
                  href={matchUrl}
                  target="_blank"
                  rel="external nofollow noopener noreferrer"
                  className={`WidgetBetWidget__oddsTile ${
                    tileType ? `WidgetBetWidget__oddsTile--${tileType}` : ''
                  }`}
                  key={`${value.id}`}
                >
                  {'label' in value &&
                  <span className="WidgetBetWidget__oddsLabel">{value.label}</span>}
                  <span className="WidgetBetWidget__oddsValue">
                    {value.value.toFixed(
                      Math.max(2, (value.value.toString().split('.')[1] || []).length)
                    )}
                  </span>
                </a>
              );
            })}
          </Fragment>
        ))}
      </div>
    </section>
  ) : null;
};

export default WidgetBet;
