import {Dispatch} from 'redux';
import {sportEventsByGroupId, sportEventsByIds} from 'API/sportEvents';
import {
  SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE,
  SPORTEVENTS_BY_GROUP_ID_GET_REQUEST,
  SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE,
} from '../../actionTypes';
import {
  HomepageWidgetMatchesGetInvalidateAction,
  HomepageWidgetMatchesGetRequestAction,
  HomepageWidgetMatchesGetResponseAction,
} from '../types';
import {CompetitionTablesTeam, MatchSimple, ScoreboardPanel} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {beMatchSimpleToMatchSimple, beStandingToCompetitionTableTeams} from '../../../API/utils';
import {standingBySeasonIdOrGroupName} from '../../../API/standings';

/**
 * Action creator for the get request of the competitionTables by season id and groupname
 * @param id
 */
const getHomepageMatchesGetRequest = (): HomepageWidgetMatchesGetRequestAction => ({
  type: SPORTEVENTS_BY_GROUP_ID_GET_REQUEST,
});

/**
 * Action creator for the get response of the competitionTables by season id and groupname
 */
const getHomepageMatchesGetResponse = (
  widgetId: string,
  matches: MatchSimple[],
  isMobile?: boolean
): HomepageWidgetMatchesGetResponseAction => ({
  type: SPORTEVENTS_BY_GROUP_ID_GET_RESPONSE,
  payload: {widgetId, data: {matches, isMobile}},
});

/**
 * Action creator for the get invalidation of the competitionTables by season id and groupname
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getHomepageMatchesGetInvalidate = (
  error: Error
): HomepageWidgetMatchesGetInvalidateAction => ({
  type: SPORTEVENTS_BY_GROUP_ID_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * Thunk for getting the team competitionTables in the current season and group
 * @param seasonId
 * @param groupName
 */
const getHomepageMatches =
  (widgetId: string, groupId: string, isMobile?: boolean) =>
  (dispatch: Dispatch): Promise<any> => {
    dispatch(getHomepageMatchesGetRequest());

    return sportEventsByGroupId(groupId)
      .then(results => results.map(beMatchSimpleToMatchSimple))
      .then((matches: MatchSimple[]) =>
        dispatch(getHomepageMatchesGetResponse(widgetId, matches, isMobile))
      )
      .catch((error: Error) => dispatch(getHomepageMatchesGetInvalidate(error)));
  };

export default getHomepageMatches;
