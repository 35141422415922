import React, {FC} from 'react';
import {TabList, Tab} from 'react-tabs';

import './styles.scss';

interface Props {
  tabs: string[];
}

const PillTabList: FC<Props> = ({tabs}: Props) => (
  <TabList className="PillTabList">
    {tabs.map(tab => (
      <Tab
        className={`PillTabList__tab ${tab.includes('Live') ? 'PillTabList__tab--live' : ''}`}
        selectedClassName="PillTabList__tab--selected"
        key={tab}
      >
        {tab}
      </Tab>
    ))}
  </TabList>
);

// eslint-disable-next-line
// @ts-ignore
PillTabList.tabsRole = 'TabList';

export default PillTabList;
