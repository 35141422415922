import {Dispatch} from 'redux';
import {sportEventsByIds} from 'API/sportEvents';
import { getBetDataById } from 'API/betData';

import {
  SB_LIVE_DATA_BY_IDS_GET_INVALIDATE,
  SB_LIVE_DATA_BY_IDS_GET_REQUEST,
  SB_LIVE_DATA_BY_IDS_GET_RESPONSE,
} from '../../actionTypes';
import {
  ScoreboardPanelsGetInvalidateAction,
  ScoreboardPanelsGetRequestAction,
  ScoreboardPanelsGetResponseAction,
} from '../types';
import {CompetitionTablesTeam, MatchSimple, ScoreboardPanel} from '../../../utils/types';
import standardizeError from '../../../utils/standardizeError';
import {beBetDataToBetData, beBetDataToBetDataScores, beMatchSimpleToMatchSimple, beStandingToCompetitionTableTeams} from '../../../API/utils';
import {standingBySeasonIdOrGroupName} from '../../../API/standings';

/**
 * Action creator for the get request of the competitionTables by season id and groupname
 * @param id
 */
const getScoreboardPanelsGetRequest = (): ScoreboardPanelsGetRequestAction => ({
  type: SB_LIVE_DATA_BY_IDS_GET_REQUEST,
});

/**
 * Action creator for the get response of the competitionTables by season id and groupname
 */
const getScoreboardPanelsGetResponse = (
  widgets: ScoreboardPanel[]
): ScoreboardPanelsGetResponseAction => ({
  type: SB_LIVE_DATA_BY_IDS_GET_RESPONSE,
  payload: {widgets},
});

/**
 * Action creator for the get invalidation of the competitionTables by season id and groupname
 * @param error
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getScoreboardPanelsGetInvalidate = (error: Error): ScoreboardPanelsGetInvalidateAction => ({
  type: SB_LIVE_DATA_BY_IDS_GET_INVALIDATE,
  error: standardizeError(error),
});

/**
 * Thunk for getting the team competitionTables in the current season and group
 * @param seasonId
 * @param groupName
 */
const getScoreboardPanels =
  (panels: {widgetId: string; matchId?: string}[]) =>
  (dispatch: Dispatch): Promise<any> => {
    dispatch(getScoreboardPanelsGetRequest());
    const ids = panels.map(p => p.matchId).filter(id => typeof id === 'string') as string[];

    return sportEventsByIds(ids)
      .then(results => results.map(beMatchSimpleToMatchSimple))
      .then(async (matches: MatchSimple[]) => {   
        const widgets: ScoreboardPanel[] = [];
        
        matches.forEach((match) => {
          const panel = panels.find(m => m.matchId === match._id);
          if (panel) {
            if (!match.betMatch) {
              widgets.push({id: panel.widgetId, match, bet:undefined});
              dispatch(getScoreboardPanelsGetResponse(widgets));
            }  

            else getBetDataById(match.betMatch._id)
              .then(res => widgets.push({id: panel.widgetId, match, bet:beBetDataToBetDataScores(res)}))
              .then(() => dispatch(getScoreboardPanelsGetResponse(widgets)))
              
          } 
        });   
      })
      .catch((error: Error) => dispatch(getScoreboardPanelsGetInvalidate(error)));
  };

export default getScoreboardPanels;
