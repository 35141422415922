import {ScoresWidgetsAction, ScoresWidgetsState} from './types';
import {
  MATCH_LIVE_DATA_BY_IDS_GET_INVALIDATE,
  MATCH_LIVE_DATA_BY_IDS_GET_REQUEST,
  MATCH_LIVE_DATA_BY_IDS_GET_RESPONSE,
} from '../actionTypes';

const initialState: ScoresWidgetsState = {
  isFetching: false,
  didInvalidate: false,
};

/**
 * Derives the 'standingsWidgets' state by reducing a new one every time an involved action is dispatches.
 * If the action is not involved (meaning it's not related with this state), then the state
 * is not changed.
 * @param state
 * @param action
 */
const scoresWidgetsReducer = (state: ScoresWidgetsState = initialState, action: ScoresWidgetsAction): ScoresWidgetsState => {
  switch (action.type) {
    case MATCH_LIVE_DATA_BY_IDS_GET_REQUEST:
      return ({
        ...state,
        isFetching: true,
        error: undefined,
      });
    case MATCH_LIVE_DATA_BY_IDS_GET_RESPONSE: {
      return ({
        ...state,
        isFetching: false,
        didInvalidate: false,
        data: {
          ...(state.data || {}),
          [action.payload.widgetId]: {
            id: action.payload.widgetId,
            header: action.payload.header,
            matches: action.payload.matches
          }
        },
      });
    }
    case MATCH_LIVE_DATA_BY_IDS_GET_INVALIDATE:
      return ({
        ...state,
        isFetching: false,
        didInvalidate: true,
        error: action.error,
      });
    default:
      return state;
  }
};

export default scoresWidgetsReducer;
